/* Загальні стилі */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

/* Навігація */
nav.header-navigation {
    display: flex;
    justify-content: space-between;
    background-color: #007bff;
    padding: 10px 20px;
    color: white;
}

nav.header-navigation a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
}

nav.header-navigation button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

nav.header-navigation a:hover,
nav.header-navigation button:hover {
    text-decoration: underline;
}

/* Контейнер */
.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Форми */
form {
    min-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

form > div {
    margin-bottom: 15px;
}

form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

form button:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
}

form textarea {
    width: 100%;
    min-width: 400px;
}

/* Списки */
ul.list {
    list-style-type: none;
    padding: 0;
}

ul.no-indent {
    padding-left: 0;
}

ul.list li {
    background: #f9f9f9;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

ul.list li:hover {
    background-color: #ddd;
}

.table-bordered tr > td {
    border: 1px solid #ddd;
    background: #f9f9f9;
}

.table-bordered tr:hover > td {
    background-color: #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Заголовки */
h1,
h2 {
    text-align: center;
    color: #007bff;
}

/* p {
    text-align: center;
} */

/* Спінер */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-left-color: #007bff;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.dashboard-columns {
    display: flex;
    gap: 20px;
}

.column {
    flex: 1;
    background: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    margin-top: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #0056b3;
}

.template-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.template-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 10px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
}

.template-item:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.template-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.template-language {
    font-weight: bold;
    color: #666;
}

.template-name {
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
    transition: color 0.3s ease;
}

.template-name:hover {
    color: #0056b3;
}

.edit-icon {
    color: #007bff;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: #0056b3;
}

.language-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.language-list:hover {
    background-color: #f9f9f9;
}

.language-list.active {
    border-color: green;
    background-color: #e8f5e9;
}

.language-list .language-code {
    flex: 1;
    font-weight: bold;
}

.language-list .language-name {
    flex: 2;
}

.language-selector {
    position: relative;
    margin-top: 10px; /* Відступ зверху */
}

.btn-group {
    position: relative;
    z-index: 1; /* Задаємо пріоритет нижче за селектор */
    margin-bottom: 20px; /* Додаємо відступ для уникнення тісного розташування */
}

.email-body-container {
    padding: 10px;
    border: 1px solid #ddd;
    background: #f9f9f9;
    border-radius: 5px;
    margin-bottom: 1em;
    overflow-wrap: break-word;
}
